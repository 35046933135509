import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './FacilitatorPage.css';

const FacilitatorPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await fetch(`http://localhost:8080/rooms/${user.roomCode}`);
        if (!response.ok) {
          throw new Error('Failed to get room data');
        }
        const data = await response.json();
        setRoomData(data);
      } catch (error) {
        console.error('Error fetching room data:', error);
        setErrorMessage('Failed to load room data. Please try again.');
      }
    };

    if (user?.roomCode) {
      fetchRoomData();
    } else {
      navigate('/'); 
    }
  }, [user, navigate]);

  const handleClearTicket = () => {
    fetch('http://localhost:8080/clear-ticket', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ roomCode: user.roomCode, name: user.name, role: user.role }),
    }).then(response => {
      if (!response.ok) {
        console.error("Failed to clear ticket");
      }
    });
  };

  const handleShowEstimates = () => {
    fetch('http://localhost:8080/show-estimates', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ roomCode: user.roomCode, name: user.name, role: user.role }),
    }).then(response => {
      if (!response.ok) {
        console.error("Failed to show estimates");
      }
    });
  };

  return (
    <div className="facilitator-page">
      <h1>Facilitator Dashboard</h1>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {roomData ? (
        <div>
          <h2>Room: {roomData.code}</h2>
          <div>
            <h3>Users</h3>
            <ul>
              {roomData.users.developers.map((dev, index) => (
                <li key={index}>{dev}</li>
              ))}
              <li>Facilitator: {roomData.users.facilitator}</li>
              {roomData.users.observers.map((obs, index) => (
                <li key={index}>{obs}</li>
              ))}
            </ul>
          </div>
          <div className="session-controls">
            <button onClick={handleClearTicket}>Clear Ticket</button>
            <button onClick={handleShowEstimates}>Show Estimates</button>
          </div>
        </div>
      ) : (
        <p>Loading room data...</p>
      )}
    </div>
  );
};

export default FacilitatorPage;
