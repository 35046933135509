import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import RoomSetupPage from "./Pages/RoomSetupPage";
import DeveloperPointingPage from "./Pages/DeveloperPointingPage";
import FacilitatorPage from "./Pages/FaciltatorPage";
import PrivateRoute from "./Utils/PrivateRoute";

function App() {
  return (
    
      <Router>
        <Routes>
          <Route path="/" element={<RoomSetupPage />} />
          <Route path="/DeveloperPointingPage" element={<PrivateRoute><DeveloperPointingPage /></PrivateRoute>} />
          <Route path="/about" element={<FacilitatorPage />} />
        </Routes>
      </Router>
    
  );
}

export default App;
