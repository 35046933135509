import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "./RoomSetupPage.css";

const RoomSetupPage = () => {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const [role, setRole] = useState("developer");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch('http://localhost:8080/sign-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, roomCode, role }),
        mode: "no-cors"
      });
  
      if (!response.ok) {
        const error = await response.json()
        console.error(error)
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setUser({
        name,
        roomCode: data.roomCode,
        role,
      });
  
      if (role === "facilitator" || role === "observer") {
        navigate("/FacilitatorPage");
      } else {
        navigate("/DeveloperPointingPage");
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setErrorMessage('There was an issue with your request. Please try again.');
    }
  };

  return (
    <div className="border-style">
      <form onSubmit={handleSubmit}>
        <label htmlFor="role">Select your role</label>
        <select
          id="roleSelector"
          value={role}
          onChange={(event) => setRole(event.target.value)}
        >
          <option value="developer">Developer</option>
          <option value="facilitator">Facilitator</option>
          <option value="observer">Observer</option>
        </select>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <label htmlFor="roomCode">Room Code</label>
        <input
          type="text"
          name="roomCode"
          value={roomCode}
          onChange={(event) => setRoomCode(event.target.value)}
        />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default RoomSetupPage;
