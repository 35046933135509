import { createContext, useCallback, useContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const hasUser = useCallback(() => {
    if (!user || !user.name || !user.roomCode || !user.role) {
      return false;
    }

    return true;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        hasUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const ctx = useContext(AuthContext)
  return ctx
}
