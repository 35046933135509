import { useState, useEffect } from "react";
import UserListInRoom from "../context/UserListInRoom";
import "./DeveloperPointingPage.css";
import { useAuth } from "../context/AuthContext";

const DeveloperPointingPage = () => {
  const { user } = useAuth();
  const [flippedCardId, setFlippedCardId] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`http://localhost:8080/rooms/${user.roomCode}`);
        if (!response.ok) {
          console.log("Failed to pull in users");
        }
        const data = await response.json();
        setUsers(data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [user.roomCode]);

  const pointCards = [
    { id: 1, cardNumber: "1", backOfCard: "You selected 1😊" },
    { id: 2, cardNumber: "2", backOfCard: "You selected 2😁" },
    { id: 3, cardNumber: "3", backOfCard: "You selected 3😆" },
    { id: 4, cardNumber: "5", backOfCard: "You selected 5😢" },
    { id: 5, cardNumber: "8", backOfCard: "You selected 8🤨" },
  ];

  const handleCardClick = (cardId) => {
    fetch('http://localhost:8080/point', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ roomCode: user.roomCode, name: user.name, role: user.role, point: cardId }),
    }).then(response => {
      if (!response.ok) {
        console.error("Failed to select point");
      }
    });

    setFlippedCardId(cardId);
  };

  return (
    <div className="developer-pointing-page">
      <h2>Select a point value</h2>
      <div className="cards-container">
        {pointCards.map((pointCard) => (
          <div
            key={pointCard.id}
            className={`card ${flippedCardId === pointCard.id ? "flipped" : ""}`}
            onClick={() => handleCardClick(pointCard.id)}
          >
            <div className="card-inner">
              <div className="card-front">
                {pointCard.cardNumber}
              </div>
              <div className="card-back">
                {pointCard.backOfCard}
              </div>
            </div>
          </div>
        ))}
      </div>
      <UserListInRoom users={users} />
    </div>
  );
};

export default DeveloperPointingPage;
